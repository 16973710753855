.hero3-header9 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.hero3-content {
  display: flex;
  justify-content: center;
}
.hero3-max-width {
  align-self: center;
  align-items: center;
}
.hero3-column {
  flex: 1;
  width: auto;
  display: flex;

  flex-shrink: 0;
  flex-direction: column;
}
.hero3-column1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.hero3-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
@media (max-width: 991px) {
  .hero3-column {
    width: 100%;
  }
  .hero3-column1 {
    width: auto;
  }
}
@media (max-width: 767px) {
  .hero3-text {
    text-align: center;
  }
  .hero3-text1 {
    text-align: center;
  }
  .hero3-actions {
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .hero3-actions {
    flex-direction: column;
  }
  .hero3-button {
    width: 100%;
  }
  .hero3-button1 {
    width: 100%;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
